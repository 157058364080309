
body {
  height: 100%;
  margin: 5%, 5%, 5%, 5%;
}

html,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.card-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.card-gauge {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}

.dateTimePickerContainer {
  display: flex;
  /* justify-content: space-around; */
  margin-top: 20px;
}

/* Textbox text color */
.dateTimePickerInput .MuiInputBase-input {
  color: grey;
}

/* Textbox border and icon color */
.dateTimePickerInput .MuiOutlinedInput-notchedOutline {
  border-color: grey;
}

.dateTimePickerInput:hover .MuiOutlinedInput-notchedOutline {
  border-color: grey;
}

.dateTimePickerInput .MuiInputLabel-root {
  color: grey;
}

.dateTimePickerInput .MuiSvgIcon-root {
  color: grey;
}

/* Popper (calendar) styling */
.dateTimePickerPopper .MuiPaper-root {
  color: grey;
}

.dateTimePickerPopper .MuiPickersDay-root {
  color: grey;
}

.dateTimePickerPopper .MuiButtonBase-root {
  color: grey;
}

.dateTimePickerPopper .MuiPickersDay-root.Mui-selected {
  background-color: grey;
}

/* Custom marker style */
.custom-marker {
  border: 2px solid;
  border-radius: 50px;
  padding: 2px 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  text-align: center;
  white-space: nowrap;
  min-width: 10px;
  font-size: 12px;
}

/* Custom marker text style */
.custom-marker-text {
  color: white;
  font-weight: bold;
}